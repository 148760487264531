import { getStaticAPIToken } from 'utils/settings';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const defaultHeaders: { [key: string]: any } = {
  'x-kb-app-locale': 'en',
  'x-kb-app-version': process.env.NEXT_PUBLIC_REACT_APP_VERSION || '000100',
  'x-kb-app-name': process.env.NEXT_PUBLIC_REACT_APP_NAME || 'khatabook',
  'x-kb-new-auth': false,
  'x-kb-platform': 'web'
};

export const staticAPIToken: { [key: string]: any } = {
  'x-api-token': getStaticAPIToken()
};
