import { ILogin, IOtp } from 'types/auth';
import KBFetch from 'api';

import { defaultHeaders } from './headers';
import { getUrl, ROUTES } from './url';

export function requestOTPAPICall(data: IOtp): Promise<unknown> {
  const body = JSON.stringify(data);
  const contentType = 'application/json';
  const method = 'POST';
  return KBFetch(ROUTES.REQUEST_OTP, getUrl(ROUTES.REQUEST_OTP), {
    method,
    body,
    headers: { 'content-type': contentType, ...defaultHeaders }
  })
    .then(response => response?.json())
    .catch(error => {
      return Promise.reject(error);
    });
}

export function dologinAPICall(data: ILogin) {
  const body = JSON.stringify(data);
  const contentType = 'application/json';
  const method = 'POST';

  return KBFetch(ROUTES.LOGIN, getUrl(ROUTES.LOGIN), {
    method,
    body,
    headers: { 'content-type': contentType, ...defaultHeaders }
  })
    .then(response => response?.json())
    .catch(error => {
      return Promise.reject(error);
    });
}
