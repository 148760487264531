import { ThemeProvider } from '@mui/material/styles';
import buildTheme from './buildTheme';

const theme = buildTheme();
// const onBoardingTheme = buildTheme()

const WithTheme = ({ children }: any) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default WithTheme;
