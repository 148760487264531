import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import { LoaderProps } from '../ts-utils';

function Loader({ type, color, boxProps, size, ...props }: LoaderProps) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }} {...boxProps}>
      {type === 'page' ? (
        <Backdrop
          open
          sx={{
            color: color || '#fff',
            zIndex: theme => theme.zIndex.drawer + 1000
          }}
        >
          <CircularProgress
            sx={{
              color: (theme: any) => color || theme.palette.grey[500]
            }}
            color={color}
            size={size}
            {...props}
          />
        </Backdrop>
      ) : (
        <CircularProgress
          sx={{
            color: (theme: any) => color || theme.palette.grey[500]
          }}
          color={color}
          size={size}
          {...props}
        />
      )}
    </Box>
  );
}
Loader.defaultProps = {
  type: 'section'
};

export default Loader;
