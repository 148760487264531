import * as muiColors from '@mui/material/colors';
import { createTheme, Theme } from '@mui/material/styles';
import { PaletteOptions as MuiPaletteOptions } from '@mui/material/styles/createPalette';
import { Shadows } from '@mui/material/styles/shadows';

export const monoFont = "'Roboto Mono', monospace";

interface __Primary {
  background: string;
  popup: string;
  fadedColor: string;
  color: string;
  heading: string;
  hightlightItem: string;
  pinkAvatarBack: string;
}
export interface PaletteOptionsCustom extends MuiPaletteOptions {
  borderColor: string;
  borderFaded: string;
  borderFaded2: string;
  sidebarPrimary: __Primary;
  textColors: {
    grey: string;
    black: string;
    white: string;
    red: string;
    dark: string;
    darkWarning: string;
    darkBlack: string;
    blackishGreen: string;
    lightBlue: string;
  };
  sidebarDark: __Primary;
  avatarColorSchemes: Array<{ bgcolor: string; color: string }>;
  commonBgColor: any;
  primary: any;
  background: any;
}
export const palette: PaletteOptionsCustom = {
  primary: {
    light: '#D1E9FF',
    main: '#175CD3',
    dark: '#194185'
  },
  secondary: {
    light: '#E6E3F6',
    main: '#5D529E',
    dark: '#484176'
  },
  error: {
    light: '#FEE4E2',
    main: '#D92D20',
    dark: '#B42318'
  },
  warning: {
    light: '#FEEEC5',
    main: '#EEB626',
    dark: '#D1A229'
  },
  success: {
    light: '#D1FADF',
    main: '#12B76A',
    dark: '#039855'
  },
  info: {
    light: '#7EA9E9',
    main: '#1566DE',
    dark: '#1857B6'
  },
  background: {
    default: '#EFF8FF',
    semiPurple: '#EDEDF7',
    grey: '#FAFAFE'
  },
  borderFaded: '#f5f5f5',
  borderFaded2: '#eaecf0',
  divider: '#D6D6D6',
  borderColor: '#737373',
  sidebarPrimary: {
    background: '#EAECF0',
    popup: '#FCFCFD',
    fadedColor: '#344054',
    color: '#000000',
    heading: '#98A2B3',
    hightlightItem: '#D0D5DD',
    pinkAvatarBack: '#DD2590'
  },
  textColors: {
    grey: '#737373',
    black: '#424242',
    white: '#FFFFFF',
    darkWarning: '#713B12',
    dark: '#1E1E23',
    red: '#912018',
    darkBlack: '#0E1725',
    blackishGreen: '#4E5E76',
    lightBlue: '#0192FF'
  },
  sidebarDark: {
    background: '#0D1726',
    popup: '#192C4D',
    fadedColor: '#8DABD8',
    color: '#FFFFFF',
    heading: '#4173BE',
    hightlightItem: '#192C4D',
    pinkAvatarBack: '#DD2590'
  },
  avatarColorSchemes: [
    { bgcolor: '#D1FADF', color: '#039855' },
    {
      bgcolor: '#D1E9FF',
      color: '#175CD3'
    },
    { bgcolor: '#FCE7F6', color: '#C11574' },
    { bgcolor: '#FDEAD7', color: '#CA8504' },
    { bgcolor: '#D1E0FF', color: '#155EEF' },
    { bgcolor: '#DDD6FE', color: '#6938EF' },
    { bgcolor: '#CFF9FE', color: '#06AED4' },
    { bgcolor: '#E3FBCC', color: '#3B7C0F' },
    { bgcolor: '#E3E8EF', color: '#424242' },
    { bgcolor: '#FEE4E2', color: '#D92D20' }
  ],
  commonBgColor: {
    lightGrey: '#F9FAFB',
    lightYellow: '#FAC515',
    lightGreen: '#3CB17C',
    lightBlue: '#3788EA',
    paleBlue: '#1491EF',
    paleGrey: '#F0F2F4',
    grey: '#F6F7F9',
    white: '#FFFFFF',
    solidWhite: '#F2F4F7',
    lightBlueMagenta: '#AEAEBB',
    lightGrayishBlue: '#E9E7FB',
    lightWhiteBlue: '#F8F7FF',
    lightPrimaryBlue: '#478BF1',
    lightCyanBlue: '#E3EDF9',
    lightCyanGreen: '#E6F4F2',
    lightRed: '#FFF4F3',
    darkRed: '#B81E26',
    lightPink: '#FDF2F3',
    light2Pink: '#FAE2E4',
    brightRed: '#DC343B',
    veryLightYellow: '#FBFDFC',
    brightBlue: '#E6F4FF',
    lowDarkBlue: '#375A8D',
    brightPink: '#F16B70',
    light2Blue: '#8DABD8',
    lightBlue2: '#EAF6F5',
    darkBlue: '#0E1725',
    light3Blue: '#028EF3',
    neonGreen: '#E3F9FA',
    fluorescentGreen: '#32D583',
    infoYellow: '#FFF3C4'
  },

  ...muiColors
};

const buildTheme = (palettePatch = {}): Theme => {
  const newPalette = {
    ...palette,
    ...palettePatch
  };

  const theme = createTheme({
    //common break-points throughout the khatabook app
    breakpoints: {
      values: {
        xs: 0,
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1280
      }
    },
    spacing: (factor: number) => `${4 * factor}px`,
    palette: newPalette,
    typography: {
      fontFamily: ['Noto Sans'].join(','),
      h1: {
        fontSize: '2rem',
        fontWeight: 600,
        lineHeight: 1.2,
        letterSpacing: '0.15000000596046448px'
      },
      h2: {
        fontSize: '1.5rem',
        fontWeight: 600,
        lineHeight: 1.25,
        letterSpacing: '0.15000000596046448px'
      },
      h3: {
        fontSize: '1.25rem',
        fontWeight: 400,
        lineHeight: 1.4,
        letterSpacing: '-0.20000000298023224px'
      },
      h4: {
        fontSize: '1rem',
        fontWeight: 600,
        lineHeight: 1.33
      },
      h5: {
        fontSize: '0.875rem',
        fontWeight: 600,
        lineHeight: 1.714,
        letterSpacing: '0.15000000596046448px'
      },
      h6: {
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: 1.714,
        letterSpacing: '1px',
        textTransform: 'uppercase'
      },
      subtitle1: {
        fontSize: '0.85rem',
        fontWeight: 400,
        lineHeight: 1.286,
        letterSpacing: '0',
        textAlign: 'left'
      },
      subtitle2: {
        fontSize: '0.75rem',
        fontWeight: 400,
        lineHeight: 1.5,
        letterSpacing: '0.5px'
      },
      body1: {
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: 1.375,
        letterSpacing: '0.15000000596046448px'
      },
      body2: {
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: 1.429,
        letterSpacing: '0.15000000596046448px'
      },
      button: {
        fontFamily: 'inherit',
        fontSize: '0.95rem',
        fontWeight: '500',
        textTransform: 'none'
      },
      overline: {
        fontSize: '0.625rem',
        fontWeight: 400,
        lineHeight: 1.4,
        letterSpacing: '0.5px'
      }
    }
  });

  theme.shadows = theme.shadows.map(shadow => {
    if (shadow === 'none') return shadow;

    return shadow.replace(/rgba\(0,0,0,0\.\d+\)/g, 'rgba(0,71,176,0.05)');
  }) as Shadows;

  return theme;
};

export default buildTheme;
