export enum EVENTS {
  OnboardingPageLoad = 'OnboardingPageLoad',
  OnboardingRequestOtp = 'OnboardingRequestOtp',
  OnboardingOtpSent = 'OnboardingOtpSent',
  LoginSuccess = 'Background Event : App Setup : Login Success',
  OnboardingResendOtpClick = 'OnboardingResendOtpClick',
  HomeLoginClick = 'HomeLoginClick',
  HomeStartTrialClick = 'HomeStartTrialClick',
  KYUUpdateSuccess = 'KYUUpdateSuccess',
  KYUPermissionPopupShown = 'KYUPermissionPopupShown',
  ExploreDemoAccountClick = 'ExploreDemoAccountClick',
  LoanPageFilled = 'LoanPageFilled',
  BTLMobNoView = 'BTLMobNoView',
  BTLSendOTPClicked = 'BTLSendOTPClicked',
  BTLMobOTPView = 'BTLMobOTPView',
  BTLApplyLoanView = 'BTLApplyLoanView',
  BTLApplyCTAClicked = 'BTLApplyCTAClicked',
  BTLPinCodeUnservicableErrorView = 'BTLPinCodeUnservicableErrorView',
  BTLBureuAnalysisLoaderView = 'BTLBureuAnalysisLoaderView',
  BTLUploadStatementView = 'BTLUploadStatementView',
  BTLUploadStatementCTAClicked= 'BTLUploadStatementCTAClicked',
  BTLStatementAnalysisLoaderView = 'BTLStatementAnalysisLoaderView',
  BTLUploadStatementErrorView = 'BTLUploadStatementErrorView',
  BTLUploadStatementTryAgainCTAClicked = 'BTLUploadStatementTryAgainCTAClicked',
  BTLLoanOfferSuccessView = 'BTLLoanOfferSuccessView',
  BTLCompleteApplicationCTAClicked = 'BTLCompleteApplicationCTAClicked',
  BTLLoanOfferRejectView = 'BTLLoanOfferRejectView',
  QRLandingPage= 'QRLandingPage',
  QRLandingPage_Click_Continue= 'QRLandingPage_Click_Continue',
  QRLandingPage_Loader= 'QRLandingPage_Loader',
  QRLandingPage_Error= 'QRLandingPage_Error',
  QRSuccess= 'QRSuccess',



}
