import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import MuiButton from '@mui/material/Button';
import { styled, StyledEngineProvider } from '@mui/material/styles';
import { makeStyles, ThemeProvider } from '@mui/styles';
import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';

import buildTheme from 'features/landing/pwa-utils/theme/buildTheme';
import { ButtonColor, ButtonProps } from '../ts-utils';

const useStyles = makeStyles((theme: any) => ({
  root: {},
  minWidth: (props: { minWidth?: string | number }): any => ({
    minWidth: props.minWidth ? props.minWidth : null
  }),
  sizeSmall: {
    fontSize: '0.75rem',
    lineHeight: 1.5,
    letterSpacing: '0.5px',
    '&$enableProgress': {
      fontSize: '1.125rem'
    }
  },
  sizeMedium: {
    '&$enableProgress': {
      fontSize: '1.5rem'
    }
  },
  sizeLarge: {
    fontSize: '1.1rem',
    '&$enableProgress': {
      fontSize: '1.88rem'
    }
  },
  enableProgress: {
    cursor: 'unset',
    '&:hover, &:focus': {
      boxShadow: theme.shadows[0]
    },
    '&.MuiButton-containedSecondary:hover': {
      backgroundColor: theme.palette.secondary.main
    }
  },
  successColorOutline: {
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main,
    '&:hover': {
      borderColor: theme.palette.success.dark
    }
  },
  successColorContained: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark
    }
  },
  successColorText: {
    color: theme.palette.success.main,
    backgroundColor: theme.palette.success.light,
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.dark
    }
  },
  errorColorOutline: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '&:hover': {
      borderColor: theme.palette.error.dark
    }
  },
  errorColorContained: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  errorColorText: {
    color: theme.palette.error.main,
    backgroundColor: theme.palette.error.light,
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.error.dark
    }
  },
  whiteColorOutline: {
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white
  },
  whiteColorContained: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white
  },
  whiteColorText: {
    color: theme.palette.common.white
  },
  primaryColorGreyOutline: {
    color: theme.palette.common.primary,
    borderColor: theme.palette.divider
  },
  baseColors: {
    color: theme.palette.borderColor,
    backgroundColor: theme.palette.common.white,
    border: '1px solid' + theme.palette.divider,
    height: 'fit-content',
    '&:hover': {
      border: '1px solid' + theme.palette.divider
    }
  }
}));

const StyledMuiButton = styled(MuiButton)(({ theme, variant }) => ({
  // fontFamily: `"Roboto","Helvetica","Arial","sans-serif"`,
  textTransform: 'none',
  minWidth: '0',
  borderRadius: '8px',
  ...(variant === 'outlined' || variant === 'contained'
    ? {
        boxShadow: theme.shadows[1],
        '&:hover': {
          boxShadow: theme.shadows[1]
        }
      }
    : {}),
  ':hover': { cursor: 'pointer' },
  '&.Mui-disabled': {
    cursor: 'not-allowed',
    pointerEvents: 'auto',
    ':hover': {
      background: 'none',
      boxShadow: 'none'
    }
  }
}));

const ButtonComponent = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const {
      className,
      minWidth,
      href,
      enableProgress,
      children,
      onClick,
      size,
      startIcon,
      color,
      variant,
      replace,
      pattern,
      border,
      ...otherProps
    } = props;

    const classes = useStyles({
      minWidth
    });

    const customProps: {
      component?: React.ElementType;
      replace?: boolean;
      to?: string;
    } = {};

    if (href) {
      customProps.component = Link;
      customProps.replace = false;
      customProps.to = href;
    }

    let newColor = color;

    if (['success', 'error', 'white'].includes(color || '')) {
      newColor = undefined;
    }

    const colorProp = newColor as ButtonColor;

    return (
      <StyledEngineProvider injectFirst>
        <StyledMuiButton
          ref={ref}
          disableElevation
          disableRipple
          {...otherProps}
          className={clsx(className, classes.root, {
            [classes.minWidth]: !!minWidth,
            [classes.enableProgress]: enableProgress,
            [classes.sizeLarge]: size === 'large',
            [classes.sizeMedium]: size === 'medium' || !size,
            [classes.sizeSmall]: size === 'small',
            [classes.successColorOutline]:
              color === 'success' && variant === 'outlined',
            [classes.successColorContained]:
              color === 'success' && variant === 'contained',
            [classes.successColorText]:
              color === 'success' &&
              (variant === 'text' || variant === undefined),

            [classes.errorColorOutline]:
              color === 'error' && variant === 'outlined',
            [classes.errorColorContained]:
              color === 'error' && variant === 'contained',
            [classes.errorColorText]:
              color === 'error' &&
              (variant === 'text' || variant === undefined),

            [classes.whiteColorOutline]:
              color === 'white' && variant === 'outlined',
            [classes.whiteColorContained]:
              color === 'white' && variant === 'contained',
            [classes.whiteColorText]:
              color === 'white' &&
              (variant === 'text' || variant === undefined),
            [classes.primaryColorGreyOutline]:
              color === 'primary' && border === 'grey',
            [classes.baseColors]: pattern === 'base'
          })}
          href={href}
          startIcon={enableProgress ? undefined : startIcon}
          onClick={enableProgress ? undefined : onClick}
          size={size}
          color={colorProp}
          variant={variant}
          {...customProps}
        >
          {!enableProgress && children}
          {enableProgress && (
            <DonutLargeIcon className="spin" fontSize="inherit" />
          )}
        </StyledMuiButton>
      </StyledEngineProvider>
    );
  }
);

ButtonComponent.displayName = 'ButtonComponent';

ButtonComponent.defaultProps = {
  minWidth: undefined,
  enableProgress: false,
  target: undefined,
  rel: undefined,
  replace: false
};

export default function Button(props: ButtonProps) {
  return (
    <ThemeProvider theme={buildTheme()}>
      <ButtonComponent {...props} />
    </ThemeProvider>
  );
}

export type { ButtonProps };
