const BASE_URL = process.env.NEXT_PUBLIC_BASE_API_URL; //TODO:update this to env variable

export enum ROUTES {
  REQUEST_OTP = 'REQUEST_OTP',
  LOGIN = 'LOGIN',
  PINCODE_SERVICEABILITY = 'PINCODE_SERVICEABILITY',
  CREATE_LEAD_IN_AIRTABLE = 'CREATE_LEAD_IN_AIRTABLE',
  LOAN_STATE = 'LOAN_STATE',
  UPDATE_LOAN_APPLICATION = 'UPDATE_LOAN_APPLICATION',
  BANK_STATEMENT_UPLOADED = 'BANK_STATEMENT_UPLOADED',
  BANK_STATEMENT_UPLOAD_URL = 'BANK_STATEMENT_UPLOAD_URL',
  GET_QR_KYC_DETAIL = 'GET_QR_KYC_DETAIL',
  GENERATE_TOKEN = 'GENERATE_TOKEN'
}

export const urls = {
  [ROUTES.REQUEST_OTP]: '/v1/auth/request-otp',
  [ROUTES.LOGIN]: '/v1/auth/login/?version=v3',
  [ROUTES.PINCODE_SERVICEABILITY]: '/locations/metadata',
  [ROUTES.CREATE_LEAD_IN_AIRTABLE]: '/leads/create-lead-in-airtable',
  [ROUTES.LOAN_STATE]:'/loans-service/apis/v1/big-ticket-loans',
  [ROUTES.UPDATE_LOAN_APPLICATION]:'/loans-service/apis/v1/big-ticket-loans/application',
  [ROUTES.BANK_STATEMENT_UPLOADED]: '/loans-service/apis/v1/big-ticket-loans/bank-statement/event/uploaded',
  [ROUTES.BANK_STATEMENT_UPLOAD_URL]:'/loans-service/apis/v1/big-ticket-loans/bank-statement/upload-url',
  [ROUTES.GET_QR_KYC_DETAIL]: '/payments/v1/merchant-onboarding/generate-link',
  [ROUTES.GENERATE_TOKEN]: '/payments/v1/merchant-onboarding/generate-token'
};
export function getUrl(route: ROUTES) {
  return BASE_URL + urls[route];
}
