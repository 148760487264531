import React from 'react';

export const ControlWrapper = ({
  shouldShow,
  children
}: {
  shouldShow: boolean;
  children: React.ReactNode;
}) => {
  if (!shouldShow) {
    return null;
  }
  return <>{children}</>;
};
