import styled from '@emotion/styled';
import InputAdornment from '@mui/material/InputAdornment';
import MuiTextField from '@mui/material/TextField';
import * as React from 'react';

import { svgIcons } from 'assets/svg-icons';
import Loader from 'atoms/loader';
import { TextFieldProps } from 'atoms/ts-utils';

const StyledDiv = styled('div', {
  shouldForwardProp: prop => prop !== 'fullWidth'
})(
  ({ theme, fullWidth }: any) =>
    `
    display: grid;
    text-align: left;
    color: ${theme.palette.textColors.grey};
    ${fullWidth ? 'width: 100%;' : 'width: fit-content;'}

    label {
      margin-bottom: 0.5rem;
      color: ${theme.palette.textColors.grey};
    }
    input.MuiInputBase-inputSizeSmall {
      font-size: 0.95rem;
    }
    .MuiOutlinedInput-root {
      border-radius: 8px;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: ${theme.palette.divider};
      border-width: 1px !important;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
`
);

const TextField = (props: TextFieldProps) => {
  const [query, setQuery] = React.useState<string>(props.defaultValue || '');
  const {
    fullWidth,
    size,
    placeholder,
    textClearable,
    loading,
    label,
    onChange,
    value,
    isSearchBox,
    endAdornment,
    startAdornment,
    inputType,
    inputRef,
    sx,
    className,
    multiline,
    disable,
    error,
    helperText,
    maxLength,
    min,
    max,
    step,
    pattern,
    toUppercase,
    toLowercase,
    onClick,
    customInputProps,
    isValidInput,
    labelColor,
    labelSize,
    ...otherProps
  } = props;

  function handleChange(e: React.BaseSyntheticEvent) {
    let value: any = e.target.value.trimStart().slice(0, maxLength);
    if (toUppercase) value = value.toUpperCase();
    else if (toLowercase) value = value.toLowercase();
    if (inputType === 'number')
      value = !isNaN(parseFloat(value)) ? parseFloat(value) : '';
    if (inputType === 'integer')
      value = !isNaN(parseInt(value)) ? parseInt(value) : '';

    e.target.value = value;
    if (isValidInput && !isValidInput(value)) return;

    setQuery(value);
    onChange && onChange(e);
  }
  const actualValue = value !== undefined ? value : query;
  let labelTag = label;
  if (label && typeof labelTag === 'string') {
    labelTag = <label>{label}</label>;
  }

  const onWheelEvent = (e: React.WheelEvent<HTMLDivElement>) => {
    if (inputType === 'number') {
      e.target instanceof HTMLElement && e.target.blur();
    }
  };

  return (
    <StyledDiv
      fullWidth={fullWidth}
      className={`text-field-div ${className || ''}`}
    >
      {labelTag}
      <MuiTextField
        size={size}
        sx={sx}
        placeholder={placeholder}
        error={error || false}
        helperText={helperText || ''}
        disabled={disable || false}
        InputProps={{
          type: inputType
            ? inputType === 'integer'
              ? 'number'
              : inputType
            : textClearable
            ? 'search'
            : undefined,
          endAdornment:
            endAdornment !== undefined ? (
              endAdornment
            ) : (
              <React.Fragment>
                {loading ? <Loader color="inherit" size={20} /> : null}
              </React.Fragment>
            ),
          inputProps: {
            min,
            max,
            pattern,
            step
          },
          onClick,
          startAdornment:
            startAdornment !== undefined ? (
              startAdornment
            ) : isSearchBox ? (
              <InputAdornment position="start">
                {svgIcons.search}
              </InputAdornment>
            ) : (
              <InputAdornment position="start">
                {props.inputIcon}
              </InputAdornment>
            ),
          ref: inputRef,
          ...customInputProps
        }}
        InputLabelProps={{ shrink: false }}
        onChange={handleChange}
        value={actualValue || ''}
        multiline={multiline}
        inputProps={{
          maxLength
        }}
        onWheel={onWheelEvent}
        {...otherProps}
      />
    </StyledDiv>
  );
};

TextField.defaultProps = {
  fullWidth: false,
  isSearchBox: false,
  size: 'small',
  placeholder: '',
  textClearable: false,
  loading: false,
  error: false,
  multiline: false,
  maxLength: 10000
  // autoComplete: 'off',
};

export default TextField;
